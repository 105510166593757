<template>
  <div class="app-container">
    <div  :id="spreadIdName" :style="spreadStyle"></div>
  </div>
</template>

<script>
function noop () {}
export default {
  name: 'excel',
  data () {
    return {
      // spreadJs 内容
      excelIo: {},
      spread: {},
      spreadNS: {},
      spreadStyle: {
        width: '100%',
        height: '630px'
      },
      spreadIdName: '',
      refName: 'excelView',
      bigFileJson: ''
    }
  },
  props: {
    // 这个功能还没有做
    idName: {
      type: String,
      default: 'excelView'
    },
    // 数据可以是文件
    fileData: Object,
    // 数据可以是json序列化
    workbookObj: Object,
    // 获取文件字符串方法
    getFileJsonString: {
      type: Function,
      default: noop
    }
  },
  mounted () {
    this.spreadIdName = this.idName
    this.initIo()
    this.initSpreadJs()
  },
  watch: {
    fileData (file) {
      if (file && Object.keys(file).length > 0) {
        this.initSpreadJsByFile(file)
      } else {
        this.clearSpreadJs()
      }
    },
    workbookObj (obj) {
      if (obj && Object.keys(obj).length > 0) {
        this.initSpreadJsByJson(obj)
      } else {
        this.clearSpreadJs()
      }
    }
  },
  methods: {
    // 初始化io流
    initIo () {
      var that = this
      const { GC } = window
      GC.Spread.Common.CultureManager.culture('zh-cn')
      that.excelIo = new GC.Spread.Excel.IO()
      that.spreadNS = GC.Spread.Sheets
    },
    // 初始化插件
    initSpreadJs: async function () {
      var that = this
      if (that.spread && Object.keys(that.spread).length > 0) {
        return
      }
      // 在弹窗中获取id
      that.$nextTick(() => {
        that.spread = new that.spreadNS.Workbook(document.getElementById(that.spreadIdName))
      })
    },
    // 清空插件内容
    clearSpreadJs: async function () {
      var that = this
      if (that.spread && Object.keys(that.spread).length > 0) {
        that.spread.clearSheets()
        that.bigFileJson = ''
        that.getFileJsonString(that.bigFileJson)
      }
    },
    // 关闭插件
    closeSpreadJs: async function () {
      var that = this
      if (that.spread && Object.keys(that.spread).length > 0) {
        that.spread.clearSheets()
        that.bigFileJson = ''
        that.getFileJsonString(that.bigFileJson)
      }
    },
    // 上传EXCEL显示
    upExcel: async function (file) {
      var that = this
      var excelFile = file
      that.excelIo.open(excelFile, (json) => {
        var workbookObj = json
        that.upExcelJson(workbookObj)
      }, function (e) {
        alert('文件读取失败，仅支持xlsx类型')
      })
    },
    upExcelJson: async function (workbookObj) {
      var that = this
      if (workbookObj) {
        that.spread.fromJSON(workbookObj)
        that.bigFileJson = JSON.stringify(workbookObj)
        that.getFileJsonString(that.bigFileJson)
      } else {
        that.spread.clearSheets()
        that.bigFileJson = ''
        that.getFileJsonString(that.bigFileJson)
      }
    },
    initSpreadJsByJson: async function (workbookObj) {
      var that = this
      await that.initSpreadJs()
      await that.upExcelJson(workbookObj)
    },
    initSpreadJsByFile: async function (file) {
      var that = this
      await that.initSpreadJs()
      await that.upExcel(file.raw)
    }
  }
}
</script>

<style scoped>

</style>
