import {
  mapState,
  mapMutations
} from 'vuex'
export const authority = {
  data () {
    return {}
  },
  computed: {
    ...mapState('menu', {
      menu: 'menu'
    })
  },
  methods: {

    ...mapMutations('action', {
      initAction: 'initAction'
    }),
    ...mapMutations('menu', {
      initMenu: 'initMenu'
    }),
    // 做权限管理的方法
    async authority (data) {
      let res = null
      if (data) {
        if (data.permissions) {
          res = data
        } else {
          res = await this.$axios.get('/api/Project/GetUserPermissionByprojectId?projectId=' + data)
        }
      } else {
        let id = localStorage.getItem('eleProjectId')
        res = await this.$axios.get('/api/Project/GetUserPermissionByprojectId?projectId=' + id)
      }
      var permissionList = res.permissions
      var actitons = {}
      for (var item of permissionList) {
        actitons[item.permissionDescribe] = true
      }
      this.initAction(actitons)
      // 找出所有menu
      let menuList = permissionList.filter(item => {
        return item.permissionType == 'Menu'
      })
      menuList = menuList.sort(function (a, b) {
        return a.sort - b.sort
      })
      // 找出所有父级menu
      let parmenuList = menuList.filter(item2 => {
        return item2.parentID == 0
      })

      // // 将每个父级的子级存进去
      var menus = []
      for (let item3 of parmenuList) {
        let obj = {}
        obj.name = item3.permissionName
        obj.index = item3.permissionDescribe
        obj.icon = item3.icon
        obj.includeCom = item3.permissionDescribe
        let arr = menuList.filter(item4 => {
          return item4.parentID == item3.id
        })
        if (arr.length != 0) {
          obj.hasChilder = true
          obj.children = []
          for (let child of arr) {
            let objc = {}
            objc.name = child.permissionName
            objc.index = child.permissionDescribe
            objc.icon = child.icon
            objc.includeCom = child.permissionDescribe
            objc.hasChilder = false
            obj.children.push(objc)
          }
        } else {
          obj.hasChilder = false
        }
        menus.push(obj)
      }
      menus = this.menu.concat(menus)
      this.initMenu(menus)
      // setTimeout(() => {
      this.$router.push('/home/table')
      // }, 10)
    }
  }
}
