import {
  mapState
} from 'vuex'
export const codeShare = {
  data () {
    return {
      toProjectList: [],
      toFloderList: [],
      toSpaceList: [],
      ruleFormCopy: {
        name: '',
        spaceId: '',
        projectType: '',
        projectId: '',
        floderId: ''
      },
      rulesCopy: {
        name: {
          required: true,
          message: '请填写样表名称',
          trigger: 'blur'
        },
        spaceId: {
          required: true,
          message: '请选择企业',
          trigger: 'change'
        },
        projectType: {
          required: true,
          message: '请选择项目分类',
          trigger: 'change'
        },
        projectId: {
          required: true,
          message: '请选择项目',
          trigger: 'change'
        },
        floderId: {
          required: true,
          message: '请选择文件夹',
          trigger: 'change'
        }
      },
      needCopyItem: {},
      codeShareDia: false,
      toSpaceIdOrProjectId: 0,
      spaceFloderData: [],
      isToSelecet: {
        common: true,
        space: false
      },
      isDisabled: false
    }
  },
  mounted () {},
  computed: {},
  methods: {
    async shareCode (item) {
      if (this.spaceId == 0) {
        await this.getSpaceList()
      } else {
        await this.toSpceChange(0)
        this.ruleFormCopy.spaceId = 0
      }
      this.needCopyItem = item
      this.codeShareDia = true
    },
    // 选择目标文件夹
    toSpceChange (id) {
      this.$axios.get(`/api/FormHandle/GetCodeLibList?SpaceId=${id}`).then(res => {
        console.log(res)
        this.toFloderList = res
      })
    },
    copyToTarget (ref) {
      let codeId = this.needCopyItem.id
      let spaceId = this.ruleFormCopy.spaceId
      let parentId = this.ruleFormCopy.floderId
      this.$refs[ref].validate(valid => {
        if (valid) {
          this.$axios.get(
            `/api/formhandle/copycodingstandard?CodingId=${codeId}&ToSpaceId=${spaceId}&ParentId=${parentId}`).then(res => {
            // console.log(res)
            this.$message({
              type: 'success',
              message: '分享成功'
            })
            this.isDisabled = false
            this.codeShareDia = false
          })
        }
      })
    },

    getSpaceList () {
      this.$axios.get('/api/Space/GetSpaceAndProjectType').then(res => {
        this.toSpaceList = res
      })
    }
  }
}
