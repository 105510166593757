<template>
	<div id="table">
		<el-upload :show-file-list="false" ref="upload" accept=".xlsx,.XLSX,.xls,.XLS" action :http-request="upload" :on-change="handleChange" v-show="false" :before-upload="beforeAvatarUpload">
			<el-button size="small" type="primary">{{$t('common.uploading')}}</el-button>
		</el-upload>
		<el-row class="elrow">
			<el-col :span="5">
				<div class="code">
					<div class="codeList">
						    <div class="codeTitle codeTitleLeft"><span>{{$t('encoder.encodedLibrary')}}</span>
							<span class="copySpan">
								<span v-if="spaceId==0?spacePermiss.isSmartAdmin:spaceAction.importcode" @click="isCreateMth" class="el-icon-folder-add" style="font-size: 20px;"></span>
							</span>
						</div>
						<div>
							<el-collapse v-model="activeNames" v-for="(floderItem,index) in codeItems" :key="index" @change="selectFloder">
								<el-collapse-item :name="floderItem.id">
									<!-- 文件夹内容 -->
									<template slot="title">
										<div class="collapse-div">
											<span v-if="!floderItem.isChange"><i style="margin-right: 5px;" class="el-icon-folder"></i> {{floderItem.excelName}}</span>
											<el-input v-focus="focusStateStand" placeholder="输入文件夹名称"  v-model="floderName" v-show="floderItem.isChange" @blur="sureChangeFloer(floderItem,index)">
												<el-button slot="append" class="elInput-circle-close" icon="el-icon-circle-close" @mousedown.stop.native="cancleUpdateFolderInfoClick()"></el-button>
											</el-input>
											<span v-show="!floderItem.isChange" >
												<i style="margin-right: 10px;" v-if="spaceId==0?spacePermiss.isSmartAdmin:spaceAction.updatecodename" class="el-icon-edit" @click.stop="updateFloder(index,floderItem)"></i>
												<i style="margin-right: 10px;" class="iconfont icon-a-huowuduanyundandaoru1" @click.stop="upload1(floderItem)" v-if="spaceId==0?spacePermiss.isSmartAdmin:spaceAction.importcode"></i>
												<i style="margin-right: 10px;" v-if="spaceId==0?spacePermiss.isSmartAdmin:spaceAction.deletecode" @click="deleteFloder(floderItem)" class="el-icon-delete"></i>
												<!-- <span> -->
											</span>
										</div>
									</template>
									<!-- 文件夹内部的内容 -->
									<div v-for="codeItem in floderItem.codingListDto" :key="codeItem.id" @click="showStandard(codeItem)" class="codeItem">
										<div class="codeTitle codeItemName" v-show="isUpdateInfoId!=codeItem.id" :class="download.id==codeItem.id?'bgcolor':''">
											<span >{{codeItem.excelName}}</span>
											<i v-if="spaceId==0?true:spacePermiss.isSmartAdmin" class="iconfont icon-daoru edit pointer right" @click.stop="shareCode(codeItem)"></i>
											<i v-if="spaceId==0?spacePermiss.isSmartAdmin:spaceAction.updatecodename" class="el-icon-edit edit pointer right" @click.stop="updateStandard(codeItem)"></i>
											<i v-if="spaceId==0?spacePermiss.isSmartAdmin:spaceAction.deletecode" class="el-icon-delete delete pointer right" @click.stop="deleteStandard(codeItem)"></i>
										</div>
										<el-input v-focus="focusState" @keyup.enter.native="updateEnter()" v-model="labelUpdateInfo" v-show="isUpdateInfoId==codeItem.id"
										 @blur="onUpdateBlurInfo(codeItems,codeItem)">
											<el-button slot="append" class="elInput-circle-close" icon="el-icon-circle-close" @mousedown.stop.native="cancleUpdateInfoClick()"></el-button>
										</el-input>
									</div>
								</el-collapse-item>
							</el-collapse>
							<!-- <el-input></el-input> -->
							<div v-if="isCreate" class="create-container">
								<el-input ref="floderInput" v-model="floderName" @blur="createFloder" :placeholder="$t('codingRule.writeContent')"></el-input>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="19" style="padding-left:20px">
				<div class="table">
					<div class="tableTitle">
						<span class="tableTitleFont" v-if="download.excelName">{{download.excelName}}</span>
						<span class="tableTitleFont" v-else>{{$t('encoder.standard')}}</span>
						<span class="codeTitleIcon">
							<!-- <el-button v-if="spaceAction.exportcode" type="primary" @click="down(download)" class="hdBtnBox-item"><i class="iconfont icon-daochu marginRight"></i>导出</el-button> -->
							<el-button v-if="(spaceId==0?spacePermiss.isSmartAdmin:spaceAction.setcodemapping)&&this.codeItems.length" type="primary"
							 @click="UpdateCodeLibMatches()" class="hdBtnBox-item"><i class="iconfont icon-bianma marginRight"></i>{{$t('codingRule.defineMappings')}}</el-button>
						</span>
					</div>
					<div class="tableItem">
						<el-table :data="tableData.slice((currpage - 1) * pagesize , currpage * pagesize)" :row-class-name="tableRowClassName"
						 :cell-style="columnStyle" border max-height="720">
							<el-table-column v-for="(value,key,index) in tableData[0]" :key="index" :prop="key" :label="key" min-width="170px"
							 align="left" :render-header="renderHeader">>
								<template slot-scope="scope">
									{{tableData.slice((currpage - 1) * pagesize , currpage * pagesize)[scope.$index][key]}}
								</template>
							</el-table-column>
						</el-table>
					</div>
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage"
					 :page-sizes="[20, 40, 60, 80]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalNum">
					</el-pagination>
				</div>
			</el-col>
		</el-row>
		<el-dialog title="定义编码及映射列" :visible.sync="UpdateCodeVisible" :close-on-click-modal="false">
			<el-form label-width="100px" class="demo-ruleForm">
				<div class="codeForm">定义编码列：<i class="el-icon-star-on" style="color:red;font-size:18px"></i></div>
				<div class="codeForm">
					<el-radio class="elradio" v-for="(matche,index) in Matches" :key="matche+index" v-model="radio1" :label="index">{{matche}}</el-radio>
				</div>
				<div class="codeForm">定义映射列：<i class="el-icon-caret-top" style="color:red;font-size:21px"></i></div>
				<div class="codeForm">
					<el-radio class="elradio" v-for="(matche,index) in Matches" :key="matche+index" v-model="radio2" :label="index">{{matche}}</el-radio>
				</div>
			</el-form>
			<p class="codeP">
				<el-button type="danger" @click="UpdateCodeVisible=false">取消</el-button>
				<el-button type="primary" @click="UpdateCodeInfo()">确定</el-button>
			</p>
		</el-dialog>
		<!-- 复制编码弹出层 -->
		<el-dialog title="复制公共编码" :visible.sync="codeDialog">
			<el-form :model="selectCopyObj" :rules="rulesCopyToThis" ref="selectCopyObj" label-width="100px" class="demo-ruleForm">
				<el-form-item label="选择编码" prop="excelName">
					<el-select v-model="selectCopyObj.excelName" placeholder="请选择">
						<el-option v-for="item in copyCommonList" :key="item.id" :label="item.excelName" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<p style="text-align: right;">
				<el-button type="primary" icon="el-icon-success" @click="copyCodeTo('selectCopyObj')">确定</el-button>
			</p>
		</el-dialog>
		<!-- 分享编码弹出层 -->
		<el-dialog :title="$t('encoder.sharedCoding')" :visible.sync="codeShareDia" :close-on-click-modal="false">
				<el-form :model="ruleFormCopy" :rules="rulesCopy" ref="ruleFormCopy" label-width="100px" class="demo-ruleForm">
					<div class="inline-box" >
						<el-form-item v-if="spaceId == 0" :label="$t('encoder.enterprise')" prop="spaceId">
							<el-select :disabled="isDisabled" v-model="ruleFormCopy.spaceId" :placeholder="$t('common.pleaseChoose')+$t('encoder.enterprise')" @change="toSpceChange">
								<el-option v-for="item in toSpaceList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
					<p v-if="spaceId != 0" style="font-size: 20px;padding: 10px 0;">此编码标准将分享至公共标准中，请选择目标文件夹</p>
					<div class="inline-box">
						<el-form-item  :label="$t('encoder.folder')" prop="floderId">
							<el-select v-model="ruleFormCopy.floderId" :placeholder="$t('common.pleaseChoose')+$t('encoder.folder')">
								<el-option v-for="item in toFloderList" :key="item.id" :label="item.excelName" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
				</el-form>
				<p style="text-align: right;">
					<el-button type="primary" icon="el-icon-success" @click="copyToTarget('ruleFormCopy')">{{$t('common.confirmation')}}</el-button>
				</p>
			</el-dialog>
	</div>
</template>

<script>
import XLSX from 'xlsx'
import axios from 'axios'
import excel from './excel.vue'
import { codeShare } from '@/mixins/codeShare'
import {
  mapState
} from 'vuex'
export default {
  mixins: [codeShare],
  data () {
    return {
      currpage: 1,
      pagesize: 20,
      totalNum: 0,
      radio1: -1,
      radio2: -1,
      UpdateCodeVisible: false,
      Matches: [], // 编码匹配项
      tableData: [],
      card: [],
      cardActive: '',
      workbook: {},
      download: {},
      codeItems: [],
      isUpdateInfoId: 0,
      focusState: false,
      labelUpdateInfo: '',
      cancleUpdate: false,
      cancleUpdateFolder: false,
      spaceId: 0,
      codeDialog: false,
      copyCommonList: [],
      rulesCopyToThis: {
        excelName: {
          required: true,
          message: '请选择公共样表',
          trigger: 'change'
        }
      },
      selectCopyObj: {
        excelName: '',
        id: 0
      },
      isCreate: false,
      floderName: '',
      activeNames: [''],
      selectFloderId: 0,
      focusStateStand: false
      // isChangeFloder:false
    }
  },
  watch: {
    // cardActive (ov, nv) {this.getTable(ov)
    // }
  },
  directives: {
    focus: {
      update: function (el, {
        value
      }) {
        if (value) {
          el.children[0].focus()
        }
      }
    }
  },
  created () {
    this.getSpaceId()
  },
  mounted () {
    this.GetCodeLibList()
  },
  computed: {
    ...mapState('spaceAction', {
      spaceAction: 'spaceAction'
    }),
    ...mapState('spacePermiss', {
      spacePermiss: 'spacePermiss'
    })
  },
  components: {
    excel
  },
  methods: {
    getSpaceId () {
      let routeName = this.$route.name
      if (routeName == 'commoncodenumber') {
        this.spaceId = 0
      } else {
        this.spaceId = localStorage.getItem('eleSpaceId')
        // 当是空间样表的时候获取公共样表作为复制的列表
        this.$axios.get(`/api/FormHandle/GetCodeLibList?SpaceId=0`).then(res => {
          for (let item of res) {
            this.copyCommonList.push(...item.codingListDto)
          }
        })
      }
    },
    columnStyle ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (this.download.matches) {
        var arr = this.download.matches.split('@')
        if (columnIndex == arr[0] || columnIndex == arr[1]) {
          return 'background:rgba(147, 195, 235,0.05);'
        }
      }
    },

    renderHeader (h, {
      column,
      $index
    }) {
      if (this.download.matches) {
        var arr = this.download.matches.split('@')
        var row1 = parseInt(arr[0])
        var row2 = parseInt(arr[1])
        return h(
          'div', {
            style: 'width:100%'
          }, [
            h('span', column.label),
            h('span', {
              class: 'floatCR',
              style: 'color:red;margin-left:15px;font-family:Eurostile Bold'
            }, [
              h('i', {
                class: row1 == $index ? 'el-icon-star-on' : (row2 == $index ? 'el-icon-caret-top' : '')
              })
            ])
          ]
        )
      } else {
        return h(
          'div', {
            style: 'width:100%'
          }, [
            h('span', column.label)
          ]
        )
      }
    },
    tableRowClassName ({
      row,
      rowIndex
    }) {
      if (rowIndex === 0) {
        // return 'displayrow';
      }
      return ''
    },
    upload1 (item) {
      // if(!this.selectFloderId){
      // 	this.$message({
      // 		type: "warning",
      // 		message: "请先选择文件夹"
      // 	})
      // 	return
      // }
      this.selectFloderId = item.id
      this.$refs['upload'].$children[0].$refs.input.click()
    },
    // 获取编码列表
    GetCodeLibList () {
      this.$axios.get(`/api/FormHandle/GetCodeLibList?SpaceId=${this.spaceId}`).then(res => {
        res.forEach(item => {
          item.isChange = false
        })
        this.codeItems = res
      })
    },
    // 修改编码匹配项
    UpdateCodeLibMatches () {
      this.radio1 = ''
      this.radio2 = ''
      if (this.Matches.length == 0) {
        this.$message({
          type: 'warning',
          message: '未选择标准文件或标准文件为空'
        })
        return
      }
      if (this.download.matches) {
        var arr = this.download.matches.split('@')
        this.radio1 = parseInt(arr[0])
        this.radio2 = parseInt(arr[1])
      }
      this.UpdateCodeVisible = true
    },
    UpdateCodeInfo () {
      if (this.radio1 == -1) {
        this.$message({
          type: 'warning',
          message: '请选择编码列'
        })
        return
      }
      if (this.radio2 == -1) {
        this.$message({
          type: 'warning',
          message: '请选择映射列'
        })
        return
      }
      if (this.radio1 == this.radio2) {
        this.$message({
          type: 'warning',
          message: '编码列和映射列不能相同'
        })
        return
      }
      var Matches = this.radio1 + '@' + this.radio2
      this.$axios.get('/api/FormHandle/UpdateCodeLibMatches?ExcelId=' + this.download.id + '&Matches=' + Matches).then(
        res => {
          this.$message({
            type: 'success',
            message: '设置成功'
          })
          this.download.matches = Matches
          this.UpdateCodeVisible = false
        })
    },

    // 上传编码excel
    upload (file, type) {
      var formData = new FormData()
      formData.append('formFile', file.file)
      formData.append('SpaceId', this.spaceId)
      formData.append('ParnetId', this.selectFloderId)
      this.$axios.post(`/api/FormHandle/ImportCodingStandard`, formData).then(res => {
        this.$message({
          type: 'success',
          message: '上传成功'
        })
        this.GetCodeLibList()
      })
    },
    beforeAvatarUpload (file) {
      const isEXCEL = (file.type === 'application/vnd.ms-excel' || file.type ===
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      if (!isEXCEL) {
        this.$message.error('上传文件只能是.xlsx、.xls格式!')
      }
      return isEXCEL
    },
    handleChange (file) {
      var file1 = {
        name: file.name,
        raw: file.raw
      }
    },
    async down (item) {
      if (JSON.stringify(this.download) == '{}') {
        this.$message({
          type: 'warning',
          message: '未选择编码标准文件'
        })
        return
      }
      var type = 'application/x-xls'
      var filePaths = []
      filePaths.push(item.excelUrl)
      let res = await this.$axios.post('/api/Base/DownFileZip', filePaths, {
        responseType: 'arraybuffer'
      })
      const href = URL.createObjectURL(new Blob([res], {
        type
      }))
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = href // 指定下载链接
      a.download = item.excelName // 指定下载文件名
      a.click() // 触发下载
      URL.revokeObjectURL(a.href) // 释放URL对象
    },
    async showStandard (item) {
      this.tableData = []
      this.download = item
      var type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      var filePaths = []
      filePaths.push(item.excelUrl)

      let res = await this.$axios.post('/api/Base/DownFileZip', filePaths, {
        responseType: 'arraybuffer'
      })

      var data = new Uint8Array(res)
      var workbook = XLSX.read(data, {
        type: 'array'
      })
      var sheetNames = workbook.SheetNames // 工作表名称集合
      this.workbook = workbook
      this.card = sheetNames
      this.cardActive = sheetNames[0]
      this.getTable(sheetNames[0])
    },
    updateStandard (item) {
      var _this = this
      _this.isUpdateInfoId = item.id
      var index = item.excelName.lastIndexOf('.')
      var name = item.excelName.substring(0, index)
      _this.labelUpdateInfo = name
      setTimeout(() => {
        _this.focusState = true
      }, 1)
    },
    updateEnter () {
      this.isUpdateInfoId = 0
      this.focusState = false
    },
    onUpdateBlurInfo (List, item) {
      if (this.cancleUpdate) {
        this.labelUpdateInfo = ''
        this.isUpdateInfoId = 0
        this.focusState = false
        this.cancleUpdate = false
      } else {
        this.updateInfo(List, item)
      }
    },
    cancleUpdateInfoClick () {
      this.cancleUpdate = true
    },
    updateInfo (List, item) {
      var index = item.excelName.lastIndexOf('.')
      var unit = item.excelName.substring(index, item.excelName.length)
      var label = this.labelUpdateInfo + unit
      if (this.labelUpdateInfo == '') {
        this.$message({
          type: 'warning',
          message: '未输入标准名称'
        })
        this.isUpdateParInfoId = 0
        this.focusStateParUpdateInfo = false
        return
      }
      if (item.excelName == label) {
        this.$message({
          type: 'warning',
          message: '标准名称未更改'
        })
        this.isUpdateInfoId = 0
        this.focusState = false
        return
      }
      this.$axios.get('/api/FormHandle/UpdateCodeTableName?ExcelName=' + label + '&TableId=' + item.id + '&ParentId=' + this.selectFloderId).then(res => {
        item.excelName = label
        this.$message({
          type: 'success',
          message: '修改成功'
        })
      })
      this.labelUpdateInfo = ''
      this.isUpdateInfoId = 0
      this.focusState = false
    },

    deleteStandard (item) {
      var _this = this
      this.$confirm('此操作将删除该标准, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/FormHandle/DeleteCodeTable?TableId=' + item.id).then(res => {
          // var index = _this.codeItems.findIndex(n => n.id == item.id)
          // _this.codeItems.splice(index, 1)
          this.GetCodeLibList()
          this.$message({
            type: 'success',
            message: res
          })
          if (item.id == this.download.id) {
            this.Matches = []
            this.tableData = []
            this.card = []
            this.cardActive = ''
            this.workbook = {}
            this.download = {}
          }
        })
      })
    },

    getTable (sheetName) {
      this.Matches = []
      this.currpage = 1
      var worksheet = this.workbook.Sheets[sheetName]
      this.tableData = XLSX.utils.sheet_to_json(worksheet)
      // 获取第一行（即表头）
      var header = XLSX.utils.sheet_to_json(worksheet, {
        header: 1
      })[0]
      var tableTitle = this.tableData[0]
      var table0 = {}
      console.log(header)
      for (var i = 0; i < header.length; i++) {
        if (tableTitle.hasOwnProperty(header[i])) {
          table0[header[i]] = tableTitle[header[i]]
        } else {
          table0[header[i]] = ''
        }
      }
      this.Matches = header
      this.tableData[0] = table0
      console.log(this.tableData)
      this.totalNum = this.tableData.length
    },

    // 获取表格的表头
    getHeaderRow (sheet) {
      const headers = []
      /* sheet['!ref']表示所有单元格的范围，例如从A1到F8则记录为 A1:F8 */
      const range = XLSX.utils.decode_range(sheet['!ref'])
      let C = range.s.r + 2 /* 从第一行开始 */
      let R = range.s.r + 2 /* 从第一行开始 */
      /* 按列进行数据遍历 */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* 查找第一行中的单元格 */
        const cell = sheet[XLSX.utils.encode_cell({
          c: C,
          r: R
        })]
        let hdr = 'UNKNOWN ' + C // <-- 进行默认值设置
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
      }
      console.log(headers)
      return headers
    },
    handleSizeChange (val) {
      this.pagesize = val
    },
    handleCurrentChange (val) {
      this.currpage = val
    },
    // 复制编码
    copyCode () {
      if (!this.selectFloderId) {
        this.$message({
          type: 'warning',
          message: '请先选择目标文件夹'
        })
        return
      }
      this.codeDialog = true
    },
    copyCodeTo () {
      console.log(this.selectFloderId)
      let CodingId = this.selectCopyObj.excelName
      let ToSpaceId = this.spaceId
      this.$axios.get(`/api/formhandle/copycodingstandard?CodingId=${CodingId}&ToSpaceId=${ToSpaceId}&ParentId=${this.selectFloderId}`).then(res => {
        this.$message({
          type: 'success',
          message: '复制成功'
        })
        // 进行前端的数据更新操作
        // 进行前端的数据更新操作
        let index = this.codeItems.findIndex(item => {
          return item.id == this.selectFloderId
        })

        let find = this.copyCommonList.find(item => {
          return item.id == CodingId
        })
        this.codeItems[index].codingListDto.push(find)
        this.codeDialog = false
      })
    },
    isCreateMth () {
      this.isCreate = true
      setTimeout(() => {
        this.$refs['floderInput'].focus()
      }, 0)
    },
    createFloder () {
      if (this.floderName == '') {
        this.$message({
          type: 'warning',
          message: '文件夹名称不能为空'
        })
        this.isCreate = false
        return
      }
      var formData = new FormData()
      formData.append('SpaceId', this.spaceId)
      formData.append('ExcelName', this.floderName)
      formData.append('ParnetId', 0)
      this.$axios.post(`/api/FormHandle/ImportCodingStandard`, formData).then(res => {
        this.$message({
          type: 'success',
          message: '创建成功'
        })
        this.isCreate = false
        this.floderName = ''
        this.GetCodeLibList()
      })
    },
    selectFloder (val) {
      this.selectFloderId = val[val.length - 1]
    },
    deleteFloder (item) {
      var _this = this
      this.$confirm('此操作将删除该文件夹, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/FormHandle/DeleteCodeTable?TableId=' + item.id).then(res => {
          var index = _this.codeItems.findIndex(n => n.id == item.id)
          _this.codeItems.splice(index, 1)
          this.$message({
            type: 'success',
            message: res
          })
          // this.GetCodeLibList()
          if (item.id == this.download.id) {
            this.Matches = []
            this.tableData = []
            this.card = []
            this.cardActive = ''
            this.workbook = {}
            this.download = {}
          }
        })
      })
    },
    updateFloder (index) {
      let item = this.codeItems[index]
      item.isChange = !item.isChange
      this.floderName = item.excelName
      this.$set(this.codeItems, index, item)
      setTimeout(() => {
        this.focusStateStand = true
      }, 1)
    },
    cancleUpdateFolderInfoClick () {
      this.cancleUpdateFolder = true
    },
    sureChangeFloer (item, index) {
      if (this.cancleUpdateFolder) {
        this.floderName = ''
        item.isChange = !item.isChange
        this.focusStateStand = false
        this.cancleUpdateFolder = false
        return
      }
      if (this.floderName == '') {
        this.$message({
          type: 'warning',
          message: '文件夹名称不能未空'
        })
        let item1 = this.codeItems[index]
        item1.isChange = !item1.isChange
        this.$set(this.codeItems, index, item1)
        this.focusStateStand = false
        return
      }
      if (item.excelName == this.floderName) {
        this.$message({
          type: 'warning',
          message: '文件夹名称未更改'
        })
        let item1 = this.codeItems[index]
        item1.isChange = !item1.isChange
        this.$set(this.codeItems, index, item1)
        this.focusStateStand = false
        return
      }
      this.$axios.get('/api/FormHandle/UpdateCodeTableName?ExcelName=' + this.floderName + '&TableId=' + item.id + '&ParentId=0').then(res => {
        item.excelName = this.floderName
        this.$message({
          type: 'success',
          message: '修改成功'
        })
        let item1 = this.codeItems[index]
        item1.isChange = !item1.isChange
        this.$set(this.codeItems, index, item1)
        this.floderName = ''
        this.focusStateStand = false
      })
    }
    // shareCode(codeItem){

    // }
  }
}
</script>

<style lang="less" scoped>
	.copySpan {
		float: right;
		right: 10px;
		cursor: pointer;
	}

	// .elrow {
	// 	// height: calc(100vh - 160px);
	// 	// 800px
	// 	height: 821px;
	// }

	.el-button--primary {
		background-color: #475065;
		border: none;
	}

	.code {
		padding: 0 0px 0 0;
		width: 100%;

		.collapse-div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			>span:nth-child(1) {
				max-width: 150px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: left;
				background-color: #fff;
				margin-left: 10px;
			}
		}

		.codeList {
			// height: calc(100vh - 150px);
			height: 830px;
			background: #fff;
			min-width: 375px;
			margin-right: 20px;
			// width: calc(100% - 20px);
			.codeTitleLeft{
				line-height: 45px !important;
			}
			.codeTitle {
				background: #475065;
				color: #fff;
				// height: 45px;
				line-height: 38px;
				text-align: left;
				padding:0 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				span {
					background-color: unset;
					font-size: 16px;
					cursor: pointer;
				}
			}

			.create-container {
				padding: 0 10px;
			}

			.codeItem {
				.codeItemName {
					cursor: pointer;
					background: unset;
					padding-left: 15px;
					color: rgb(0, 0, 0);

					span {
						font-size: 100%;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						text-align: left;
						width: 280px;
						// background-color: #000000;
						display: -moz-inline-box;
						display: inline-block;
					}

					.edit,
					.delete {
						display: none;
						color: #0094ce;
					}

					.edit {
						margin-right: 10px;
					}
				}

				.bgcolor {
					background: #cecece;
				}

				.codeItemName:hover .edit,
				.codeItemName:hover .delete {
					display: inline;
				}
			}

			.codeName {
				background: #f6f6f6;
				color: rgb(0, 0, 0);
			}

			.icon {
				font-size: 18px;
			}
		}
	}

	/deep/.el-dialog {
		width: 1000px !important;
	}

	.codeForm {
		text-align: left;
		margin-top: 20px;

		.elradio {
			margin-bottom: 20px;
		}

		// width: 10px
	}

	.codeP {
		margin-top: 60px;
		text-align: center !important;
		margin-bottom: 30px;
	}

	.table {
		// height: calc(100vh - 150px);
		// height: 780px;
		height: 830px;
		background: rgb(255, 255, 255);
		// border-radius: 10px;
		overflow-y: auto;
		// background-color: #000000;
		.tableTitle {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-left: 15px;
			height: 45px;
			box-sizing: border-box;
			// width: 1088px;
			// display:table-cell;
			// vertical-align:middle;
			text-align: left;

			// background-color: #000000;
			.tableTitleFont {
				float: left;
				margin-top: 20px
			}

			span {
				background-color: unset;
				font-size: 17px;
				cursor: pointer;
			}

			.hdBtnBox-item {
				background-color: #e2e1e1;
				color: #000000;
				margin-top: 10px;
				font-weight: 600;
				border: none;

				.marginRight {
					margin-right: 5px;
				}
			}
		}

		.codeTitleIcon {
			float: right;
			margin-right: 10px
		}

		.tableItem {
			padding: 10px;
			height: 720px;
			// margin-bottom: 10px;
			overflow: auto;

			/deep/.displayrow {
				display: none !important;
			}

		}
	}

	#table {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
		// margin-top: 60px;
		border: 1px solid #ebebeb;
		// padding: 5px;
		// padding-right: 20px;
		// width: 100%;
		// margin: 20px 0 auto;
		box-shadow: 0 0 8px 0 rgba(232, 237, 250, .6), 0 2px 4px 0 rgba(232, 237, 250, .5);
		border-radius: 10px;

		// height: 100%;
		.tab {
			margin: 0 0 10px 0;
			display: flex;
			flex-direction: row;
		}
	}

	/deep/.floatCR {
		float: right;
	}

	/* 设置滚动条的样式 */
	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #ededed;
	}

	/* 滚动槽 */
	::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: #f3f3f3;
	}

	/* 滚动条滑块 */
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: #d3d3d3;
	}
</style>

<style scoped>
	.is-active {
		background-color: #409EFF
	}
</style>
