<template>
	<div class="template-container">

		<TemplateCom></TemplateCom>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import TemplateCom from './TemplateCom.vue'
export default {
  props: {
    space: String
  },
  data () {
    return {
      searchValue: ''
    }
  },
  components: {
    TemplateCom
  },
  computed: {
		  ...mapState('action', {
		    action: 'action'
		  })

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="less">
	.template-container {
		// height: calc(100vh - 100px);
		height: 877px;
		background-color: #e5e5e5;
		/* height: calc(100vh - 240px); */
		// padding: 20px;
		box-sizing: border-box;

		.pointer{
			cursor: pointer;
		}
	}
</style>
