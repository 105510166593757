<template>
	<div class="space">
		<!-- <div class="leftMenu">
			<ul>
				<li>公共标准</li>
				<li @click="lookCode(1)" :class="activeIndex==1?'actived':''">分类编码</li>
				<li @click="lookData(2)" :class="activeIndex==2?'actived':''">数据规格</li>
			</ul>
		</div> -->
		<div class="functional-zone">
			<div class="main-left">
				<div class="template-header">
					<div class="header-left">
						<!-- <span>云空间列表</span> -->
					</div>
					<div class="header-right">
						<el-input v-model="searchValue" @keyup.enter.native="search()" :placeholder="$t('common.search')"></el-input>
						<div class="hdBtnBox">
							<el-button type="primary" icon="el-icon-search" @click="search()">{{$t('common.search')}}</el-button>
							<!-- <el-button @click="activeIndex=-1" v-if="activeIndex!=-1" type="primary"><i class="iconfont icon-fanhui marginRight"></i>返回</el-button> -->
							<el-button type="primary" @click="toAdminSpace()" v-if="permissions.addspace">{{$t('spaceType.backstage') }}</el-button>
						</div>
					</div>
				</div>

				<div class="main-box" v-if="activeIndex == -1">
					<el-collapse v-model="activeNames">
						<el-collapse-item :title="$t('spaceType.personalSpace')" name="1">
							<div class="personal-space">
								<div class="item-container" v-for="item in personalSpace" :key="item.id">
									<p class="item-title" :style="(permissions.deletespace||permissions.updatespace)?'':'justify-content:center;'">
										<!-- 该元素是为了占位使得flex的布局完成居中 -->
										<span style="font-size: 18px;font-weight: bold;color: transparent;" v-if="permissions.deletespace||permissions.updatespace">...</span>
										<span class="item-name-box" :title="item.name">{{item.spaceOwnerNickName}}的空间</span>
										<el-dropdown trigger="click" v-if="permissions.deletespace||permissions.updatespace">
											<span class="el-dropdown-link">
												<i class="el-icon-more"></i>
											</span>
											<el-dropdown-menu slot="dropdown">
												<!-- <el-dropdown-item @click.native="deleteSpace(item)" v-if="permissions.deletespace"><span class="el-icon-delete"></span>删除</el-dropdown-item> -->
												<el-dropdown-item @click.native="setSpace(item)" v-if="permissions.updatespace"><span class="el-icon-setting"></span><span v-if="item.type == 1">企业设置</span><span v-else>空间设置</span></el-dropdown-item>
												<!-- <el-dropdown-item @click.native="uploadImg(item)" v-if="permissions.updatespace"><span class="el-icon-upload2"></span>上传图片</el-dropdown-item> -->
											</el-dropdown-menu>
										</el-dropdown>
										<span v-else> </span>
									</p>
									<div class="item-img" @click="intoProject(item)">
										<el-image v-if="!item.coverPictureStr" src="../assets/default/p_space.png" fit="contain" alt :onerror="p_errorImg"
										 style="width:190px;height:100px" />
										<el-image v-else :src="item.coverPictureStr" :onerror="errorImg" fit="contain" style="width:190px;height:100px"></el-image>
									</div>
									<div class="lock" v-show="isLock(item)" @click="lockMessage(item)">
										<span class="el-icon-lock"></span>
									</div>
									<p v-if="item.isOwn" class="own-text">{{$t('common.owner')}}</p>
									<!-- <p class="own-text1">{{item.fileSizeDto.sizeStr}}</p> -->
								</div>
							</div>
						</el-collapse-item>
						<el-collapse-item :title="$t('spaceType.joinCompany')" name="2">
							<div class="company-space">
								<div class="item-container" v-for="item in companySpace" :key="item.id">
									<p class="item-title" :style="(permissions.deletespace||permissions.updatespace)?'':'justify-content:center;'">
										<!-- 该元素是为了占位使得flex的布局完成居中 -->
										<span style="font-size: 18px;font-weight: bold;color: transparent;" v-if="permissions.deletespace||permissions.updatespace">...</span>
										<span class="item-name-box" :title="item.name">{{item.name}}</span>
										<el-dropdown trigger="click" v-if="permissions.deletespace||permissions.updatespace">
											<span class="el-dropdown-link">
												<i class="el-icon-more"></i>
											</span>
											<el-dropdown-menu slot="dropdown">
												<!-- <el-dropdown-item @click.native="deleteSpace(item)" v-if="permissions.deletespace"><span class="el-icon-delete"></span>删除</el-dropdown-item> -->
												<el-dropdown-item @click.native="setSpace(item)" v-if="permissions.updatespace"><span class="el-icon-setting"></span><span
													 v-if="item.type == 1">企业设置</span><span v-else>重命名</span></el-dropdown-item>
												<el-dropdown-item @click.native="uploadImg(item)" v-if="permissions.updatespace"><span class="el-icon-upload2"></span>上传图片</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
										<span v-else> </span>
									</p>
									<div class="item-img" @click="intoProject(item)">
										<el-image v-if="true" src="../assets/default/space.png" alt fit="contain" :onerror="errorImg"
										 style="width:190px;height:100px" />
										<el-image v-else :src="item.coverPictureStr" :onerror="errorImg" fit="contain" style="width:190px;height:100px"></el-image>
									</div>
									<div class="lock" v-show="isLock(item)" @click="lockMessage(item)">
										<span class="el-icon-lock"></span>
									</div>
									<p v-if="item.isOwn" class="own-text">{{$t('common.owner')}}</p>
									<!-- <p class="own-text1">{{item.fileSizeDto.sizeStr}}</p> -->
								</div>
							</div>
						</el-collapse-item>
					</el-collapse>
				</div>
				<div class="code" v-if="activeIndex==1">
					<Excel></Excel>
				</div>
				<!-- 资产参数 -->
				<div class="code" v-if="activeIndex==2">
					<TemplateCom :space="'space'"></TemplateCom>
				</div>
			</div>
			<div class="main-right">
				<div class="item-header">
					<span>{{$t('spaceType.history')}}</span>
				</div>
				<ul class="item-main">
					<li v-for="item in spaceViewList" :key="item.Guid" class="pointer" @click="toProject(item)">
						<i>
							<img src="../assets/projectmini.png" alt="">
							<span>{{item.ProjectName}}</span>
						</i>
						<span class="time">{{item.DateTime.substring(0,10)}}</span>
					</li>
				</ul>
			</div>

		</div>
		<el-dialog :title="addOrEdit=='add'?'添加企业':'编辑企业'" :close-on-click-modal="false" :visible.sync="showDialog" width="30%"
		 center>
			<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" label-position="left" style="text-align: left;">
				<el-form-item label="企业名称" prop="Name">
					<el-input v-model="ruleForm.Name" placeholder="企业名称" />
				</el-form-item>
				<el-form-item v-if="ruleForm.type == 1" label="企业编码" prop="Descript">
					<el-input v-model="ruleForm.Descript" placeholder="企业编码" />
				</el-form-item>
				<el-form-item label="封面图:" v-if="addOrEdit=='add'">
					<el-upload ref="uploadAdd" :show-file-list="false" action list-type="picture-card" :http-request="adduploadPicture"
					 :on-change="addgetPicture" accept=".bmp,.jpg,.png,.jpeg,.BMP,.JPG,.PNG,.JPEG">
						<img v-if="addPictureUrl" :src="addPictureUrl" class="avatarPic">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<div class="addBtn">
				<el-button type="danger" @click="showDialog=false">取消</el-button>
				<el-button type="primary" @click="submit('ruleForm')" v-if="addOrEdit=='add'">添加</el-button>
				<el-button type="primary" @click="submit('ruleForm')" v-else>保存</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="editDialog" :close-on-click-modal="false" title="上传封面图">
			<el-form label-width="80px" label-position="left">
				<el-form-item label="封面图" style="text-align:left">
					<el-upload ref="uploadAdd" :show-file-list="false" action list-type="picture-card" :http-request="uploadPicture"
					 :on-change="getPicture" accept=".bmp,.jpg,.png,.jpeg,.BMP,.JPG,.PNG,.JPEG">
						<img v-if="PictureUrlService" :src="PictureUrlService" class="avatarPic">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<div style="text-align:center;">
				<el-button type="danger" @click="editDialog = false">取消</el-button>
				<el-button type="primary" @click="changeImg">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
  mapState,
  mapMutations
} from 'vuex'
import Excel from '@/components/excel/netExcel.vue'
import TemplateCom from '../views/system/TemplateCom.vue'
import TopBar from '@/components/layout/TopBar.vue'
import Template from './system/Template.vue'
import {
  authority
} from '@/mixins/authorityProject'
var formData = new FormData()
var addformData = new FormData()
export default {
  mixins: [authority],
  data () {
    return {
      a: this.$route,
      admin: false, // 判断是否是超级管理员
      addPictureUrl: '',
      editDialog: false,
      PictureUrlService: '',
      spaceList: [],
      spaceViewList: [],
      searchValue: '',
      errorImg: 'this.src="' + require('../assets/default/space.png') + '"',
      p_errorImg: 'this.src="' + require('../assets/default/p_space.png') + '"',
      imgUrl: require('../assets/project.png'),
      showDialog: false,
      SearchDataList: [],
      addOrEdit: 'add',
      ruleForm: {
        Id: '',
        Name: '',
        Descript: '',
        SpaceSize: '',
        NumberAccount: '',
        ExpireTime: ''
      },
      rules: {
        Name: [{
          required: true,
          message: '企业名不能为空',
          trigger: 'blur'
        }, {
          validator: this.$utils.nameCheck,
          trigger: 'change'
        }],
        Descript: [{
          required: true,
          message: '企业编码不能为空',
          trigger: 'blur'
        }],
        SpaceSize: [{
          required: true,
          message: '空间大小不能为空',
          trigger: 'blur'
        }],
        NumberAccount: [{
          required: true,
          message: '空间人数不能为空',
          trigger: 'blur'
        }],
        ExpireTime: [{
          required: true,
          message: '截止日期不能为空',
          trigger: 'blur'
        }]
      },
      permissions: {
        addspace: false,
        updatespace: false,
        deletespace: false
      },
      isSuperAdmin: false,
      activeIndex: -1,
      personalSpace: [],
      yuansiPersonalSpace: [],
      companySpace: [],
      yuansiCompantSpace: [],
      activeNames: ['1', '2'],
      allLock: false,
      ownPersonalSpace: [],
      ownerCompanySpace: [],
      logoSrc: 'https://www.emdataer.com:8028/Doc/123.png'
    }
  },
  created () {
    localStorage.removeItem('ownerSpace')
    localStorage.removeItem('currentSpace')
  },
  mounted () {
    this.init()
    this.removeProjectList()
    this.initLogo(this.logoSrc)
    // console.log(this.a)
  },
  computed: {
    ...mapState('spaceAction', {
      spaceAction: 'spaceAction'
    })
  },
  methods: {
    ...mapMutations('spaceAction', {
      initspaceAction: 'initspaceAction'
    }),
    ...mapMutations('project', {
      initSpace: 'initSpace'
    }),
    ...mapMutations('project', {
      initProjectType: 'initProjectType'
    }),
    ...mapMutations('project', {
      initProject: 'initProject'
    }),
    ...mapMutations('spacePermiss', {
      initSpacePermiss: 'initSpacePermiss'
    }),
    ...mapMutations('logo', {
      initLogo: 'initLogo'
    }),
    removeProjectList () {
      localStorage.removeItem('projectList')
      localStorage.removeItem('eleSpaceId')
    },
    selcity (projectList) { // 查询
      var that = this // 与this区别开
      var compare = function (obj1, obj2) {
        var val1 = obj1.DateTime
        var val2 = obj2.DateTime
        if (val1 > val2) {
          return -1
        } else if (val1 < val2) {
          return 1
        } else {
          return 0
        }
      }
      var loginUserId = JSON.parse(localStorage['USER_INFO']).userId
      this.$db.transaction(function (tx) {
        tx.executeSql('select * from ProjectView1', [], function (tx, res) {
          for (var i = 0; i < res.rows.length; i++) {
            var view = JSON.parse(JSON.stringify(res.rows[i]))
            if (loginUserId == view.UserID) {
              that.spaceViewList.push(view)
            }
            that.spaceViewList.sort(compare)
            if (that.spaceViewList.length > 5) {
              that.spaceViewList.length = 5
            }
          }
        }, function (tx, err) {
          console.log(err)
        })
      })
    },
    init () {
      this.$axios.get('/api/Space/GetSpaceAndProjectTypeAndSystemPermission').then(res => {
        console.log(res)
        this.allLock = res.disable
        localStorage.setItem('allLock', this.allLock)
        // 判断一下是否是超级管理员
        if (res.userProjectPermissionDto && res.userProjectPermissionDto.roles[0].builtInRole == 'BuiltIn_SuperAdmin') {
          this.isSuperAdmin = true
          localStorage.setItem('superAdmin', true)
          this.initSpacePermiss({
            isAdmin: false,
            isSmartAdmin: true,
            isSpaceOwner: false
          })
        } else {
          this.isSuperAdmin = false
          this.initSpacePermiss({
            isAdmin: false,
            isSmartAdmin: false,
            isSpaceOwner: false
          })
        }
        if (res.userProjectPermissionDto) {
          this.admin = true
          if (res.userProjectPermissionDto.permissions) {
            console.log(res.userProjectPermissionDto.permissions)
            for (var i = 0; i < res.userProjectPermissionDto.permissions.length; i++) {
              var per = res.userProjectPermissionDto.permissions[i]
              if (per.permissionDescribe == 'addspace') this.permissions.addspace = true
              if (per.permissionDescribe == 'updatespace') this.permissions.updatespace = true
              if (per.permissionDescribe == 'deletespace') this.permissions.deletespace = true
            }
          }
        }
        var projectList = []
        this.spaceList = res.spaceDtos
        for (let item of this.spaceList) {
          if (item.type == 1) {
            if (item.spaceOwnerId == localStorage.getItem('USER_ID')) {
              item.isOwn = true
              localStorage.setItem('ownerCompany', item.id)
              this.ownerCompanySpace.push(item)
            } else {
              this.companySpace.push(item)
            }
          } else {
            if (item.spaceOwnerId == localStorage.getItem('USER_ID')) {
              item.isOwn = true
              localStorage.setItem('ownerPerson', item.id)
              this.ownPersonalSpace.push(item)
            } else {
              this.personalSpace.push(item)
            }
          }
        }
        this.companySpace = this.ownerCompanySpace.concat(this.companySpace)
        this.personalSpace = this.ownPersonalSpace.concat(this.personalSpace)

        this.yuansiPersonalSpace = JSON.parse(JSON.stringify(this.personalSpace))
        this.yuansiCompantSpace = JSON.parse(JSON.stringify(this.companySpace))
        localStorage.setItem('spaceList', JSON.stringify(this.spaceList))
        this.SearchDataList = res.spaceDtos
        res.spaceDtos.forEach(space => {
          if (space.projectTypeListDto && space.projectTypeListDto.length > 0) {
            space.projectTypeListDto.forEach(proType => {
              if (proType.projectListDto && proType.projectListDto.length > 0) {
                projectList = projectList.concat(proType.projectListDto)
              }
            })
          }
        })

        this.selcity(projectList)
      })
    },
    search () {
      var value = this.searchValue
      if (value == '') {
        this.personalSpace = this.yuansiPersonalSpace
        this.companySpace = this.yuansiCompantSpace
      } else {
        this.personalSpace = this.yuansiPersonalSpace.filter(res => {
          return res.name.indexOf(value) != -1
        })
        this.companySpace = this.yuansiCompantSpace.filter(res => {
          return res.name.indexOf(value) != -1
        })
      }
    },
    toAdminSpace () {
      this.$router.push('/adminSpace')
    },
    intoProject (space) {
      // this.initLogo(space.coverPictureStr)
      // 目前默认为
      localStorage.setItem('eleSpaceId', space.id)
      localStorage.setItem('currentSpace', JSON.stringify(space))
      // 保存企业编码
      localStorage.setItem('enterpriseCode', space.descript)
      this.$axios.get(`/api/space/getuserpermissionbyspaceid?spaceId=${space.id}`).then(res => {
        this.authoritySpace(space.id)
        console.log(this.spaceList)
        this.initSpace(this.spaceList)
        this.$router.push('/projectType')
      })
    },
    authoritySpace (id) {
      this.$axios.get(`/api/space/getuserpermissionbyspaceid?spaceId=${id}`).then(res => {
        console.log(res)
        var roleList = res.permissions
        var actitons = {}
        for (var item of roleList) {
          actitons[item.permissionDescribe] = true
        }
        // console.log(actitons)
        this.initspaceAction(actitons)
      })
    },
    // 判断是否锁了
    isLock (item) {
      // console.log(item)
      // 当前时间
      if (this.isSuperAdmin) {
        return false
      } else {
        if (this.allLock) {
          console.log(1111111)
          return true
        } else {
          if (item.statu < 0 || item.isExpire) {
            return true
          } else {
            return false
          }
        }
      }
      // return true
    },
    // 直接进入项目的之后需要获取分类列表和项目列表
    getTypeList (sid) {
      var _this = this
      this.$axios.get('/api/Project/GetProjectBySpaceId?spaceId=' + sid).then(res => {
        // console.log(res)
        var projectList = res
        this.pulluserprojectList = []
        // 获取项目
        for (var i = 0; i < projectList.length; i++) {
          projectList[i].positionNum = i + 1
          projectList[i].projectTypeId = projectList[i].id
          projectList[i].id = 'card' + projectList[i].id
          projectList[i].name = projectList[i].projectTypeName
        }
        this.initProjectType(projectList)
      })
    },
    getProjectList (sid, tid) {
      this.$axios.get('/api/Project/GetProjectBySpaceId?spaceId=' + sid).then(res => {
        console.log(res)
        var projectList = []

        var indexPro = res.find(n => n.id == tid)
        if (indexPro) {
          projectList = indexPro.projectListDto
        }
        for (var i = 0; i < projectList.length; i++) {
          projectList[i].src = ''
          projectList[i].positionNum = i + 1
          projectList[i].projectId = projectList[i].id
          projectList[i].id = 'card' + projectList[i].id
          projectList[i].name = projectList[i].projectName
        }
        console.log(projectList)
        this.initProject(projectList)
      })
    },

    async toProject (item) {
      await this.$axios.get(`/api/space/GetSpaceById?spaceId=${item.SpaceID}`).then(res => {
        console.log(res)
        if (res.isExpire) {
          this.$message({
            type: 'error',
            message: '项目所在企业已过期'
          })
          return
        }
        if (res.statu == 0) {
          this.$message({
            type: 'error',
            message: '项目所在企业被禁用'
          })
          return
        }
				 this.getTypeList(item.SpaceID)
				 this.getProjectList(item.SpaceID, item.ProjectTypeID)
				 this.$axios.get(`/api/project/judgeuserpermiss?SpaceId=${item.SpaceID}&UserId=${localStorage.getItem('USER_ID')}`).then(res => {
          console.log(res)
          this.userPermiss = res
          // localStorage.setItem('userPermiss',JSON.stringify(res))
          this.initSpacePermiss(res)
        })
        this.initSpace(this.spaceList)
        localStorage.setItem('eleSpaceId', item.SpaceID)
        localStorage.setItem('enterpriseCode', item.SpaceCode)
        localStorage.setItem('eleProjectTypeId', item.ProjectTypeID)
        localStorage.setItem('projectName', item.ProjectName)
        // console.log(item)
        // return
        var loginUserId = JSON.parse(localStorage['USER_INFO']).userId
        var that = this
        this.$axios.get('/api/Project/GetUserPermissionByprojectId?projectId=' + item.ProjectID).then(res => {
          var userMessage = JSON.parse(localStorage.getItem('USER_INFO'))
          userMessage.roles = res.roles
          localStorage.setItem('USER_INFO', JSON.stringify(userMessage))
          var spaceId = item.SpaceID
          var projectTypeID = item.ProjectTypeID
          this.authoritySpace(item.SpaceID)
          this.authority(item.ProjectTypeID)
          this.$db.transaction(function (tx) {
            // 如果表中数据为空，则插入数据
            tx.executeSql('SELECT * FROM ProjectView1', [], function (tx, res) {
              // tx.executeSql('SELECT COUNT(*) AS NUM FROM ProjectView1',[],function(tx,res){
              var haveView = false
              for (var i = 0; i < res.rows.length; i++) {
                var view = JSON.parse(JSON.stringify(res.rows[i]))
                if (loginUserId == view.UserID & item.ProjectID == view.ProjectID) {
                  haveView = true
                }
              }
              if (haveView) {
                tx.executeSql('DELETE FROM ProjectView1 WHERE ProjectID = ? and UserID = ?', [item.ProjectID,
                  loginUserId
                ])
              }
              var dateTime = that.getFullTime()
              var guid = that.guid()
              var sql =
								"insert into ProjectView1(Guid, SpaceID, ProjectTypeID, ProjectID, UserID, ProjectName,SpaceCode,ProjectCode, DateTime)values('" +
								guid + "'," + spaceId + ',' + projectTypeID + ',' + item.ProjectID + ',' + loginUserId + ",'" + item.ProjectName +
								"','" + item.SpaceCode + "','" + item.ProjectCode + "','" + dateTime + "')"
              tx.executeSql(sql)
            }, function (tx, err) {
              console.log(err)
            })
          })
          localStorage.setItem('eleProjectId', item.ProjectID)
        }).catch((r) => {
          this.$db.transaction(function (tx) {
            tx.executeSql('DELETE FROM ProjectView1 WHERE ProjectID = ? and UserID = ?', [item.ProjectID, loginUserId])
            var index = that.spaceViewList.findIndex(n => n.ProjectID == item.ProjectID && n.UserID == loginUserId)
            that.spaceViewList.splice(index, 1)
          })
        })
      })
    },
    addSpace () {
      this.ruleForm = {
        Name: '',
        Descript: ''
      }
      addformData = new FormData()
      this.addPictureUrl = ''
      this.addOrEdit = 'add'
      this.showDialog = true
    },
    // 判断addformData是否有该属性
    haveAttu (name, value) {
      if (addformData.get(name) == null) {
        addformData.append(name, value)
      } else {
        addformData.set(name, value)
      }
    },
    submit (ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          if (this.addOrEdit == 'add') {
            this.haveAttu('Name', this.ruleForm.Name)
            this.haveAttu('Descript', this.ruleForm.Descript)
            this.haveAttu('SpaceSize', this.ruleForm.SpaceSize)
            this.haveAttu('NumberAccount', this.ruleForm.NumberAccount)
            this.haveAttu('ExpireTime', this.ruleForm.ExpireTime)
            this.$axios.post('/api/Space/AddSpace', addformData).then(res => {
              var space = {}
              space.id = res.id
              space.coverPictureStr = res.headPortrait
              space.name = this.ruleForm.Name
              space.descript = this.ruleForm.Descript
              space.spaceSize = this.ruleForm.SpaceSize
              space.numberAccount = this.ruleForm.NumberAccount
              space.expireTime = this.ruleForm.ExpireTime
              space.creationTimeStr = this.getFullTime(0)
              space.projectTypeListDto = []
              this.SearchDataList.push(space)
              this.$message({
                type: 'success',
                message: '添加成功'
              })
              this.showDialog = false
            })
          } else {
            this.$axios.post('/api/Space/UpdateSpace', this.ruleForm).then(res => {
              var id = this.ruleForm.Id
              var index = this.spaceList.findIndex(n => n.id == id)
              this.spaceList[index].name = this.ruleForm.Name
              this.spaceList[index].descript = this.ruleForm.Descript
              this.spaceList[index].spaceSize = this.ruleForm.SpaceSize
              this.spaceList[index].numberAccount = this.ruleForm.NumberAccount
              this.spaceList[index].expireTime = this.ruleForm.ExpireTime
              this.$message({
                type: 'success',
                message: '修改成功'
              })
              this.showDialog = false
            })
          }
        }
      })
    },
    getFullTime (i) {
      let date = new Date() // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + ''
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours())
      let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes())
      let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
      if (i == 1) {
        return Y + '-' + M + '-' + D
      } else {
        return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
      }
    },
    guid () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0
        var v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
    // 删除项目
    deleteSpace (item) {
      this.$confirm('此操作将删除该企业空间, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/Space/DeleteSpaceById?spaceId=' + item.id).then(res => {
          this.$message({
            type: 'success',
            message: res
          })
          var index = this.spaceList.findIndex(n => n.id == item.id)
          this.spaceList.splice(index, 1)
        })
      })
    },
    setSpace (item) {
      console.log(item)
      this.ruleForm = {
        Id: item.id,
        Name: item.name,
        Descript: item.descript,
        SpaceSize: item.spaceSize,
        NumberAccount: item.numberAccount,
        type: item.type,
        ExpireTime: new Date(item.expireTime)
      }
      this.addOrEdit = 'edit'
      this.showDialog = true
    },
    uploadImg (item) {
      this.ruleForm = {
        Id: item.id,
        Name: item.name,
        Descript: item.descript
      }
      this.editDialog = true
      this.PictureUrlService = item.coverPictureStr
      formData = new FormData()
      if (this.PictureUrlService) {
        var index = this.PictureUrlService.indexOf('Doc/Project')
        var url = this.PictureUrlService.substring(index, this.PictureUrlService.length)
        formData.append('CoverPicture', url)
      }
    },
    uploadPicture (file) {
      formData.append('FormFile', file.file)
    },
    getPicture (file, fileList) {
      this.PictureUrlService = file.url
    },
    adduploadPicture (file) {
      addformData.append('FormFile', file.file)
    },
    addgetPicture (file, fileList) {
      this.addPictureUrl = file.url
    },
    changeImg () {
      if (this.PictureUrlService) {
        var id = this.ruleForm.Id
        formData.append('Id', this.ruleForm.Id)
        formData.append('statu', 1)
        this.$axios.post('/api/Project/UpdateCoverPicture', formData).then(res => {
          this.$message({
            type: 'success',
            message: '上传成功'
          })
          var index = this.spaceList.findIndex(n => n.id == id)
          this.spaceList[index].coverPictureStr = res.headPortrait
          this.editDialog = false
        })
      }
    },
    lockMessage (item) {
      console.log(item)
      if (item.statu < 0) {
        this.$message.error('该空间已被禁用，请联系管理员。')
      } else {
        this.$message.error('服务已到期，请联系管理员。')
      }
    },
    lookCode (type) {
      this.activeIndex = type
    },
    lookData (type) {
      this.activeIndex = type
    }
  },
  components: {
    TopBar,
    // Template
    TemplateCom,
    Excel
  }
}
</script>

<style scoped lang="less">
	.add {
		display: flex;
		padding: 10px;

		p {
			margin-top: 13px;
			margin-right: 10px;
		}

		.el-input {
			width: 80%;
		}
	}

	.addBtn {
		margin-left: 69%;
		margin-top: 20px;
	}

	.space {
		display: flex;
		justify-content: space-between;
		height: 877px;
		.functional-zone {
			display: flex;
			min-height: calc(100vh - 80px);
			height: 860px;
			min-width: calc(100vw - 136px);
			width: 1760px;
			// background-color: #000000;
			padding: 15px 25px;
			box-sizing: border-box;
			overflow: hidden;
			.main-left {
				width: 76%;
				min-height: calc(100vh - 90px);
			}

			.template-header {
				display: flex;
				// background-color: #ffffff;

				justify-content: space-between;
				// box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
				border-radius: 8px;
				align-items: center;
				box-sizing: border-box;
				padding: 0 20px 0 0;
				font-size: 18px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #343434;
				margin-bottom: 10px;
				.header-right {
					display: flex;
					height: 40px;

					.hdBtnBox {
						display: flex;
						margin-left: 10px;

						.el-button--primary {
							background-color: #475065;
							border: none;
						}

						.el-button--primary:nth-child(3) {
							background-color: #475065;
						}

						.hdBtnBox-item {
							background-color: #475065;
							border: none;
						}
					}
				}
			}

			.main-right {
				width: 445px;
				min-width: 22%;
				min-height: calc(100vh - 90px);
				height: 863px;
				// background-color: #000000;
				margin-left: 20px;
				box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
				border-radius: 8px;
				overflow: hidden;

				.item-header {
					display: flex;
					height: 47px;
					background-color: #F8F8F9;
					justify-content: space-between;
					align-items: center;
					box-sizing: border-box;
					padding: 0 30px;
					font-size: 16px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #343434;
					// p {
					// 	span {
					// 		cursor: pointer;
					// 		margin: 0 5px;
					// 	}
					// }
				}

				.item-main {
					background-color: #FFFFFF;
					min-height: calc(100vh - 138px);
					height: 799px;
					overflow-y: auto;

					.pointer {
						cursor: pointer;
					}

					li {
						display: flex;
						align-items: center;
						justify-content: space-between;
						height: 50px;
						border-bottom: 1px solid #e1e1e1;
						padding: 0 10px;
						font-weight: bold;
						padding: 0 20px;
						font-size: 12px;

						.time {
							// font-size: 12px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #888888;
						}

						i {
							display: flex;
							align-items: center;

							div {
								position: relative;
								width: 35px;
								height: 35px;
								border-radius: 50%;
								overflow: hidden;
								background-color: #efefef;

								img {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									height: 15px;
									width: 15px;
								}
							}

							span {
								margin-left: 15px;
							}
						}

					}
				}
			}
		}

		.main-box {
			height: calc(100vh - 140px);
			min-height: 797px;
			overflow-y: auto;
			overflow-y: overlay;
			padding-right: 20px;
			box-sizing: border-box;

			// display: flex;
			// justify-content: space-between;
			// justify-content: flex-start;
			// flex-wrap: wrap;
			// align-content: flex-start;
			/deep/.el-collapse-item__header {
				box-sizing: border-box;
				padding: 0 30px;
				margin: 10px 0;
				border-top: 1px solid #8c8c8c;
				border-bottom: 1px solid #8c8c8c;
			}

			/deep/.el-collapse-item__content {
				background-color: #e8e8e8;
			}

			/deep/.el-collapse {
				// width: calc(100% - 20px);
				box-sizing: border-box;
				padding-right: 10px;
				border: none;
			}

			/deep/.el-collapse-item__wrap {
				border: none;
			}

			.personal-space,
			.company-space {
				display: flex;
				justify-content: space-between;
				justify-content: flex-start;
				flex-wrap: wrap;
				align-content: flex-start;
			}

			.title-space {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 30px;
				height: 50px;
				font-size: 18px;
				font-weight: bold;
				background-color: #fff;
				border-top: 1px solid #a5a5a5;
				border-bottom: 1px solid #a5a5a5;
				box-sizing: border-box;
				width: 98%;
				margin: 10px 0;

				span:nth-child(2) {
					cursor: pointer;
				}
			}

			.addSpace {
				padding-top: 100px;
				box-sizing: border-box;
				width: 280px;
				height: 268px;
				background: #FFFFFF;
				box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
				border-radius: 2px;
				cursor: pointer;
				margin: 10px 20px 10px 0;

				span {
					font-size: 44px;
					color: #d8d8d8;
				}

				p {
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #444444;
					line-height: 25px;
					margin-top: 5px;
				}
			}

			.item-container {
				position: relative;
				margin: 10px 20px 10px 0;
				width: 190px;
				height: 145px;
				background: #FFFFFF;
				box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
				border-radius: 2px;
				.own-text{
					position: absolute;
					bottom: 5px;
					left: 5px;
					background-color: #2f374a;
					color: #fff;
					padding:0 5px;
				}
				.own-text1{
					position: absolute;
					bottom: 5px;
					right: 5px;
					background-color: #2f374a;
					color: #fff;
					padding:0 5px;
				}
				.lock {
					position: absolute;
					display: flex;
					align-items: center;
					justify-content: center;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					// line-height: 268px;
					background-color: rgba(0, 0, 0, 0.65);
					font-size: 60px;
					color: #fff;
					cursor: pointer;
				}

				.item-title {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 42px;
					background: #F7F7F8;
					box-sizing: border-box;
					padding: 0 20px;
					border-top: 4px solid #414141;
					font-weight: 500;
					color: #444444;

					.item-name-box {
						// background-color: #000000;
						max-width: 200px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				.item-img {
					// position: relative;
					// height: 100px;
					cursor: pointer;

					// .img {
					// 	position: absolute;
					// 	left: 50%;
					// 	top: 50%;
					// 	transform: translate(-50%, -50%);
					// }
				}

			}
		}
	}
</style>
